import { createSlice } from "@reduxjs/toolkit"

export interface UserInterface {
    user:{
        name:string,
        email:string,
        dialingCode:string,
        phoneNumber:number
    } | null
}

const initialState:UserInterface = {
    user:null
}

const userSlice = createSlice({
    name:"user",
    initialState:initialState,
    reducers:{
        setUser:(state, action) => {
            state.user = action.payload
        }
    }
})

export default userSlice.reducer;
