import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import apiRoutes from "api/apiRoutes";
import apiService from "api/apiService";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email().required("Email is required."),
  phone: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone must be 10 digits.")
    .max(10, "Phone must be 10 digits."),
  message: yup.string().required("Message is required."),
});

const EnquiryModal = ({ setOpenEnquiryModal, fetchInquiry }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    const _form = new FormData();
    _form.append("name", data?.name);
    _form.append("email", data?.email);
    _form.append("phone", data?.phone);
    _form.append("message", data?.message);
    _form.append("game", "Card");
    apiService
      .post(apiRoutes.ENQUIRY.SEND, _form)
      .then((response) => {
        fetchInquiry();
        setOpenEnquiryModal(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="md:w-[50vw] relative">
        <div className="font-semibold text-xl text-center">Enquiry Form</div>
        <div className="mt-4">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5 justify-center h-[60%] flex-grow"
          >
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    type="text"
                    placeholder="Name"
                    className="w-full"
                    size="small"
                    error={Boolean(errors?.name?.message)}
                    helperText={errors?.name?.message}
                  />
                </>
              )}
            />
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    type="email"
                    placeholder="Email"
                    className="w-full"
                    size="small"
                    error={Boolean(errors?.email?.message)}
                    helperText={errors?.email?.message}
                  />
                </>
              )}
            />
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    type="number"
                    placeholder="Phone"
                    className="w-full"
                    size="small"
                    error={Boolean(errors?.phone?.message)}
                    helperText={errors?.phone?.message}
                  />
                </>
              )}
            />
            <Controller
              name="message"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    type="text"
                    placeholder="Message"
                    className="w-full"
                    size="small"
                    error={Boolean(errors?.message?.message)}
                    helperText={errors?.message?.message}
                  />
                </>
              )}
            />
            <div className="flex justify-center">
              <button
                type={loading ? "button" : "submit"}
                className="bg-red-bg py-2 px-4 rounded-md text-white"
              >
                {loading ? "Processing..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
        <button
          className="font-semibold py-2 px-3 text-sm rounded-full text-red-500 absolute -right-4 -top-0"
          onClick={() => setOpenEnquiryModal(false)}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export default EnquiryModal;
