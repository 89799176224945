import PageNotFound from "components/PageNotFound";
import Login from "pages/auth/login/Login";
import Signup from "pages/auth/signup/Signup";
import HomePage from "pages/HomePage";
import OTPverification from "pages/auth/OTPverification";
import PersonalDetails from "pages/auth/PersonalDetails";
import Game from "pages/game/GamePlay";
import Navbar from "components/Navbar";
import SelectPrize from "pages/game/SelectPrize";
import GameResultScreen from "pages/game/GameResult";
import GameLobby from "pages/game/GameLobby";
import Enquiry from "pages/enquiry/Enquiry";

export const appPath = {
  home: "/",
  login: "/auth/login",
  signup: "/auth/signup",
  otp: "/auth/otp-verify",
  personalDetails:"/auth/personal-details",
  selectPrize:"/game/select-prize",
  gamePlay:"/game/play/:id",
  gameResult:"/game/result",
  gameLobby: "/game/lobby",
  enquiry: "/enquiry",
  notFound: "*"
};

export const routes = [
  {
    path: appPath.login,
    component: <Login />,
  },
  {
    path: appPath.signup,
    component: <Signup />,
  },
  {
    path:appPath.otp,
    component:<OTPverification />
  },
  {
    path: appPath.notFound,
    component: <PageNotFound />,
  },
  {
    path:appPath.home,
    component:<HomePage />
  },
  {
    path:appPath.selectPrize,
    component:<SelectPrize />
  },
  {
    path:appPath.gamePlay,
    component:<Game />
  },
  {
    path:appPath.personalDetails,
    component:<PersonalDetails />
  },
  {
    path:appPath.gameResult,
    component:<GameResultScreen />
  },
  {
    path: appPath.gameLobby,
    component: <GameLobby />
  },
  {
    path: appPath.enquiry,
    component: <Enquiry />
  }
];
