import React, { useEffect, useState } from "react";
import profileImage from "../../assets/images/profile1.jpeg";
import profileImage2 from "../../assets/images/profile2.jpeg";
import profileImage3 from "../../assets/images/profile3.jpeg";
import profileImage4 from "../../assets/images/profile4.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { appPath } from "routes/routes";
import { echo } from "utils/pusher/PusherClient";
import userLocalStorage from "hooks/userLocalStorage";

const players = [
  {
    name: "Player 1",
    profileImg: profileImage,
  },
  {
    name: "Player 2",
    profileImg: profileImage2,
  },
  {
    name: "Player 3",
    profileImg: profileImage3,
  },
  {
    name: "Player 4",
    profileImg: profileImage4,
  },
];

const GameLobby = () => {
  const [time, setTime] = useState(3);
  const navigate = useNavigate();
  const location = useLocation();
  const { getLocalStorage } = userLocalStorage();
  const user = JSON.parse(getLocalStorage("user_data"));

  useEffect(()=>{
    console.log('first')
    echo.channel(`card-game-join${user?.id}`).listen("CardGameJoinEvent", (e: any) => {
      console.log(e, "event received");
    });
  }, [])

  useEffect(() => {
    if (location?.state?.game_id) {
      const second = setInterval(() => {
        if (time == 0) {
          // navigate(appPath.gamePlay, {
          //   state: {
          //     game_id: location?.state?.game_id,
          //     game_type: location?.state?.game_type,
          //   },
          // });
        } else {
          setTime(time - 1);
        }
      }, 1000);

      return () => {
        clearInterval(second);
      };
    }
  }, [time]);

  return (
    <div className="h-screen grid place-items-center">
      <div className="flex flex-col items-center gap-10 justify-center w-full">
        <div className="flex justify-evenly items-center w-full h-full">
          {players?.map((item, index) => {
            return (
              <div key={index} className="grid gap-4">
                <div className="w-[150px] h-[140px]">
                  <img
                    src={item?.profileImg}
                    alt="profile image"
                    width={150}
                    height={150}
                    className="object-cover rounded-[10px] w-full h-full"
                  />
                </div>
                <p className="text-center font-semibold text-white">
                  {item?.name}
                </p>
              </div>
            );
          })}
        </div>
        <div className="text-xl font-semibold text-white">
          Game starting in {time}
        </div>
      </div>
    </div>
  );
};

export default GameLobby;
