import appConfig from "config/appConfig";
import Echo from "laravel-echo";
import Pusher from "pusher-js";


// export const PusherClient = new Pusher(appConfig.pusherKey, {
//   cluster: "ap2",
//   wsHost: appConfig.pusherAppUrl,
//   // wsPort: 6001,
//   enabledTransports: ["ws",],
//   forceTLS: false,
// });

// export const echo = new Echo({
//   // client: PusherClient,
// });

export const PusherClient = new Pusher(appConfig.pusherKey, {
  
  cluster: "ap2",
  wsHost: appConfig.pusherAppUrl,
  wsPort: 8001,
  wssPort: 8001,
  forceTLS: true,
  enabledTransports: ['ws', 'wss'],
  disableStats: true,
});

// export const echo = new Echo({
//   broadcaster: 'reverb',
//   client: PusherClient, 
//   key: appConfig.pusherKey, 
//   cluster: 'ap2', 
//   forceTLS: false, 
//   encrypted: false, 
// });

export const echo = new Echo({
  broadcaster: 'pusher',
  client: PusherClient,
});