import logo from "assets/images/Logo.png";
import TextInput from "components/inputs/TextInput";
import SubmitButton from "components/buttons/SubmitButton";
import { useNavigate } from "react-router-dom";
import { appPath } from "routes/routes";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import userLocalStorage from "hooks/userLocalStorage";
import { useEffect } from "react";

const PersonalDetails = () => {
  const navigate = useNavigate();

  const schema = yup.object().shape({
    name: yup.string().required("Name is Required"),
    email: yup
      .string()
      .email("Enter valid email")
      .required("Email is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      email: "",
    },
    resolver: yupResolver(schema),
  });
  const {getLocalStorage} = userLocalStorage();
  const user = JSON.parse(getLocalStorage("user_data"));

  useEffect(()=>{
    console.log(user)
    setValue("name", user?.name)
  }, [])

  const submitDetails = (data) => {
    navigate(appPath.home);
  };

  return (
    <div className="flex flex-col justify-start items-center h-full w-full px-20 py-10">
      <div className="flex items-center">
        <img src={logo} height={"auto"} width={"30%"} />
      </div>
      <form
        className="flex flex-col gap-6 lg:w-1/4 text-white"
        onSubmit={handleSubmit(submitDetails)}
      >
        <Controller
          name="name"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextInput
              {...field}
              type="text"
              placeholder="Enter name"
              error={errors?.name?.message}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextInput
              {...field}
              type="text"
              placeholder="Enter email"
              error={errors?.email?.message}
            />
          )}
        />

        <SubmitButton text={"Continue"} type="submit" />
      </form>
    </div>
  );
};

export default PersonalDetails;
