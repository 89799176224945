export const teamSize = {
    single:"single",
    multi:"multi"
}

export const cardTypeEnum = {
    diamond:"diamond",
    heart:"heart",
    spade:"spade",
    club:"club"
}
