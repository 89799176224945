import TextInput from "components/inputs/TextInput"

const Login = () => {
    return (
        <div>
            <TextInput variant="secondary" />
        </div>
    )
}

export default Login