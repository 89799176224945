import { configureStore } from "@reduxjs/toolkit";
import gameReducer from "./gameReducer";
import userReducer from "./userReducer";

export const store = configureStore({
    reducer:{
        game:gameReducer,
        user:userReducer
    }
})

