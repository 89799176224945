import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import ReactCardFlip from "react-card-flip";
import { toast } from "react-toastify";
import { generateRandomArray } from "./functions/commonFunctions";

interface Props {
  cardType: string;
  index: number;
  droppedCardTypes: Array<string>;
  cardData: Array<any>;
  handleActiveDeck: (val: number) => void;
  activeDeck: number | any;
}

const CardGroup = ({
  cardType,
  index,
  droppedCardTypes,
  cardData,
  handleActiveDeck,
  activeDeck,
}: Props) => {
  const [totalClicks, setTotalClicks] = useState<number>(0);
  const [numbersUsed, setNumbersUsed] = useState<Array<number>>([]);
  const [currentCard, setCurrentCard] = useState<number | null>(null);
  const [isCardDisable, setIsCardDisable] = useState([]);
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const toggleFlip = () => setIsFlipped((prev) => !prev);

  function generateNextNumber() {
    // const availableNumbers = Array.from(Array(10).keys()).filter(
    //   (num) => !numbersUsed.includes(num)
    // );
    const availableNumbers = generateRandomArray().filter(
      (num) => !numbersUsed.includes(num)
    );
    // const randomIndex = Math.floor(Math.random() * 10);
    const nextIndex = availableNumbers.length - 1;
    const randomNumber = availableNumbers[nextIndex];
    setNumbersUsed([...numbersUsed, randomNumber]);
    return randomNumber;
  }

  const changeCurrentCard = () => {
    handleActiveDeck(index);
    if (droppedCardTypes?.includes(cardType)) {
      toggleFlip();
      setIsCardDisable((prev) => [...prev, cardType]);
      return;
    }
    if (totalClicks === 10) {
      toggleFlip();
      setIsCardDisable((prev) => [...prev, cardType]);
      return;
    }
    const nextNum = generateNextNumber();
    setCurrentCard(nextNum);
    setTotalClicks((prev) => prev + 1);
  };

  useEffect(() => {
    if (droppedCardTypes?.includes(cardType)) {
      setIsFlipped(false);
      setIsCardDisable((prev) => [...prev, cardType]);
    }
  }, [droppedCardTypes]);

  useEffect(() => {
    if (totalClicks != 10) {
      if (activeDeck == index) {
        setIsFlipped(true);
      } else {
        setIsFlipped(false);
      }
    }
  }, [activeDeck, index]);

  return (
    <div>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <img
          src={"/cards/cardBack.png"}
          height={1000}
          width={1000}
          alt="card"
          draggable="false"
          className={clsx(
            "cursor-pointer h-[90px] min-w-[60px] w-[60px] md:h-[150px] md:min-w-[100px] md:w-[100px]",
            isCardDisable?.includes(cardType) && "grayscale pointer-events-none"
          )}
          onClick={() => {
            changeCurrentCard();
            toggleFlip();
          }}
        />
        <div onClick={changeCurrentCard} className="relative">
          <img
            src={"/cards/cardBack.png"}
            height={1000}
            width={1000}
            draggable="false"
            alt="card"
            className="cursor-pointer h-[90px] min-w-[60px] w-[60px] md:h-[150px] md:min-w-[100px] md:w-[100px] pointer-events-none"
          />

          {currentCard !== null ? (
            <Draggable
              key={currentCard}
              draggableId={`card-${cardType}/${currentCard}`}
              index={index}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{ ...provided.draggableProps.style }}
                  className={clsx(
                    "top-[0!important] left-[0!important] absolute h-[90px] min-w-[60px] w-[60px] md:h-[150px] md:min-w-[100px] md:w-[100px]",
                    droppedCardTypes?.includes(cardType) &&
                      "pointer-events-none"
                  )}
                >
                  <img
                    src={`/cards/${cardType}/${currentCard}.png`}
                    alt="card"
                    draggable="false"
                    className="cursor-pointer fixed z-50 top-2 right-2 h-[90px] min-w-[60px] w-[60px] md:h-[150px] md:min-w-[100px] md:w-[100px]"
                  />
                  {/* <div
                    className="fixed right-[50%] translate-x-[40%] inset-0 flex items-center justify-center h-[200px]"
                    style={{ pointerEvents: "none" }} // Prevent any pointer events interference
                  >
                    <p className="text-black font-bold text-2xl">
                      {currentCard}
                    </p>
                  </div> */}
                </div>
              )}
            </Draggable>
          ) : null}
        </div>
      </ReactCardFlip>
    </div>
  );
};

export default CardGroup;
