import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { appPath, routes } from "./routes";
import { useEffect } from "react";
import userLocalStorage from "hooks/userLocalStorage";
import apiService from "api/apiService";
import apiRoutes from "api/apiRoutes";
import { useDispatch } from "react-redux";
import { setWalletBalance } from "store/gameReducer";

const Routing = () => {
  const navigate = useNavigate();
  const { getLocalStorage, setLocalStorage } = userLocalStorage();
  const location = useLocation();
  const dispatch = useDispatch();

  const updateWalletBalance = () => {
    apiService
      .get(apiRoutes.USER.WALLET_BALANCE)
      .then((response) => {
        if (response) {
          let user = JSON.parse(getLocalStorage("user_data"));
          user.wallet_balance = response?.data;
          setLocalStorage("user_data", JSON.stringify(user));
          dispatch(setWalletBalance(response?.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const user =
      getLocalStorage("token") != "undefined" ? getLocalStorage("token") : "";
    if (!user) {
      navigate(appPath.signup);
    }
  }, []);

  useEffect(() => {
    const user =
      getLocalStorage("token") != "undefined" ? getLocalStorage("token") : "";
    if (user) {
      updateWalletBalance();
    }
  }, [location.pathname]);

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.component} />
      ))}
    </Routes>
  );
};

export default Routing;
