import React from "react";
import profile1 from "../../assets/images/profile1.jpeg";
import profile2 from "../../assets/images/profile2.jpeg";
import profile3 from "../../assets/images/profile3.jpeg";

const players = [
  {
    imgUrl: profile1,
    remaining_numbers: 23,
  },
  {
    imgUrl: profile2,
    remaining_numbers: 23,
  },
  {
    imgUrl: profile3,
    remaining_numbers: 23,
  },
];

const MultiPlayerBox = ({ playersData, userData }) => {
  return (
    <div className="flex md:flex-col justify-evenly h-full gap-4">
      {playersData?.map((player, index) => {
        return (
          player?.user_id != userData?.id && (
            <div className="grid gap-2 place-items-center">
              <div className="h-[50px] w-[50px] md:h-[80px] md:w-[80px]">
                <img
                  src={profile1}
                  alt="player profile"
                  width={80}
                  height={80}
                  className="rounded-full w-full h-full object-cover"
                />
              </div>
              <div className="flex items-center gap-4 font-semibold text-white text-sm flex-wrap justify-center text-center">
                <p>Player id:</p>
                <span>{player.id}</span>
                {/* <p>Remaining Number:</p>
              <span>{player.remaining_numbers}</span> */}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default MultiPlayerBox;
