// Note: Change the `environment` property to "production" when deploying the app
// to the live environment. Set it to "development" when running locally or in
// a test environment to enable debugging and development features.

const appConfig = {
  appName: "Navi Card Game",
  environment: "development",
  apiUrl: {
    dev: "https://panel.navismartgame.com/api/",
    live: "",
  },
  nonHeaderComps: [
    "/auth/login",
    "/auth/signup",
    "/auth/otp-verify",
    "/auth/personal-details",
    "/game/result",
  ],
  nonFooterComps: ["/game/play", "/game/result", "/auth/signup", "/auth/otp-verify"],
  pusherKey: "87909e984abafa89a22c",
  pusherAppUrl: "panel.navismartgame.com"
};

export default appConfig;
